import React from 'react'
import get from 'lodash/get'
import { graphql } from 'gatsby'
import scrollTo from 'gatsby-plugin-smoothscroll'

import Layout from '../components/layout'
import Seo from '../components/seo'
import HeroSplit from '../components/hero-split'

import * as styles from './number.module.css'

class NumberTemplate extends React.Component {
  componentDidMount() {
    const slug = get(this.props, 'pageContext.slug', '')
    scrollTo(`#${slug}`)
  }

  render() {
    const numbers = get(this.props, 'data.allContentfulCharacter.nodes', [])
    return (
      <Layout className="number-template">
        <Seo title="Numbers" />
        {numbers.map((number) => (
          <div
            key={number.id}
            id={number?.slug}
            className={styles.container}
          >
            {number.images && <HeroSplit images={number?.images} />}
          </div>
        ))}
      </Layout>
    )
  }
}

export default NumberTemplate

export const pageQuery = graphql`
  query AllNumbers {
    allContentfulCharacter(
      filter: { isNumber: { eq: true } }
      sort: { fields: [order, character] }
    ) {
      nodes {
        id
        slug
        character
        images {
          description
          gatsbyImage(layout: FULL_WIDTH, placeholder: BLURRED, height: 1000)
        }
      }
    }
  }
`
